<template>
  <!-- Version par défaut avec effet ripple -->
  <div>
    <div v-if="type === 'default'"
         class="button py-1 flex justify-center rounded-md select-none"
         :style="customStyles"
         :class="customClass"
         :disable="disabled"
         @mouseup="createRipple">
      <span class="gap-1 flex items-center">
      <Icon
        v-if="icon && !icon.includes('isax') && !iconAfter"
        :name="icon"
      />
      <i
        v-else-if="icon && !iconAfter"
        :class="icon"
        class="isax"
        style="font-size: 1rem"
      />
      <p v-if="text">{{ text }}</p>
      <slot v-else />
      <UIcon v-if="link === ''" name="i-heroicons-arrow-top-right-on-square" />
      <Icon v-if="icon && !icon.includes('isax') && iconAfter" :name="icon" />
      <i
        v-else-if="icon && iconAfter"
        :class="icon"
        class="isax"
        style="font-size: 1rem"
      />
    </span>
    </div>

    <!-- Version avec tooltip -->
    <UTooltip v-else-if="tooltip" :text="tooltip">
      <VsButton
        :active="active"
        :block="block"
        :class="props.className"
        :color="color || 'primary'"
        :disabled="disabled"
        :flat="flat"
        :loading="loading"
        class="button__primary"
      >
      <span class="gap-1 flex items-center">
        <Icon
          v-if="icon && !icon.includes('isax') && !iconAfter"
          :name="icon"
        />
        <i
          v-else-if="icon && !iconAfter"
          :class="icon"
          class="isax"
          style="font-size: 1rem"
        />
        <p v-if="text">{{ text }}</p>
        <slot v-else />
        <UIcon
          v-if="link === ''"
          name="i-heroicons-arrow-top-right-on-square"
        />
        <Icon
          v-if="icon && !icon.includes('isax') && iconAfter"
          :name="icon"
        />
        <i
          v-else-if="icon && iconAfter"
          :class="icon"
          class="isax"
          style="font-size: 1rem"
        />
      </span>
      </VsButton>
    </UTooltip>

    <!-- Version alternative -->
    <VsButton
      v-else
      :active="active"
      :block="block"
      :class="props.className"
      :color="color || 'primary'"
      :disabled="disabled"
      :flat="flat"
      :loading="loading"
      class="button__primary"
    >
    <span class="gap-1 flex items-center">
      <Icon
        v-if="icon && !icon.includes('isax') && !iconAfter"
        :name="icon"
      />
      <i
        v-else-if="icon && !iconAfter"
        :class="icon"
        class="isax"
        style="font-size: 1rem"
      />
      <p v-if="text">{{ text }}</p>
      <slot v-else />
      <UIcon
        v-if="link === ''"
        name="i-heroicons-arrow-top-right-on-square"
      />
      <Icon
        v-if="icon && !icon.includes('isax') && iconAfter"
        :name="icon"
      />
      <i
        v-else-if="icon && iconAfter"
        :class="icon"
        class="isax"
        style="font-size: 1rem"
      />
    </span>
    </VsButton>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { VsButton } from "vuesax-ts";

const props = defineProps({
  active: { type: Boolean, default: false },
  block: { type: Boolean, default: false },
  className: { type: String, default: "" },
  color: { type: String, default: "primary" },
  disabled: { type: Boolean, default: false },
  flat: { type: Boolean, default: false },
  icon: { type: String, default: null },
  iconAfter: { type: Boolean, default: false },
  link: { type: String, default: null },
  loading: { type: Boolean, default: false },
  text: { type: String, default: null },
  tooltip: { type: String, default: null },
  type: { type: String, default: null },
  // Props pour définir les couleurs via des tokens Tailwind (ex : "elf-green-600")
  bgColor: { type: String, default: "elf-green-500" },
  hoverBgColor: { type: String, default: "elf-green-600" },
  textColor: { type: String, default: "#fff" }
});

const customClass = computed(() => {
  return (props.className ? ` ${ props.className }` : "") + (props.disabled ? " disabled" : "");
});

/**
 * Si la couleur ressemble à une valeur CSS (hex, rgb, etc.), on la renvoie directement.
 * Sinon, on construit la référence à la variable CSS correspondante.
 */
const resolveColor = (color: string) => {
  if (color.startsWith("#") || color.startsWith("rgb")) {
    return color;
  }
  return `var(--color-${ color })`;
};

const customStyles = computed(() => ({
  "--bg-color": resolveColor(props.bgColor),
  "--hover-bg-color": resolveColor(props.hoverBgColor),
  "--text-color": resolveColor(props.textColor),
  // La box-shadow utilisera toujours la couleur de fond
  "--box-shadow": `0 4px 6px ${ resolveColor(props.bgColor) }`,
  "--hover-box-shadow": `0 8px 12px ${ resolveColor(props.bgColor) }`
}));

function createRipple(event: MouseEvent) {
  const button = event.currentTarget as HTMLElement;
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const circle = document.createElement("span");
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${ diameter }px`;

  const rect = button.getBoundingClientRect();
  circle.style.left = `${ event.clientX - rect.left - radius }px`;
  circle.style.top = `${ event.clientY - rect.top - radius }px`;

  circle.classList.add("ripple");
  button.appendChild(circle);

  setTimeout(() => {
    circle.remove();
  }, 600);
}
</script>

<script lang="ts">
export default {
  name: "Button"
};
</script>

<!-- Styles spécifiques au bouton (scopés) -->
<style lang="scss" scoped>
.button {
  position: relative;
  overflow: hidden;
  transition: background-color 400ms, box-shadow 400ms, transform 200ms;
  background-color: var(--bg-color);
  color: var(--text-color);
  cursor: pointer;
  font-weight: 500;
  text-align: center;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.shadow--1 {
  box-shadow: var(--box-shadow);
}

.button:hover:not(.disabled) {
  transform: translateY(-2px);
  background-color: var(--hover-bg-color);
}

.shadow--1:hover {
  box-shadow: var(--hover-box-shadow);
}

.button:active {
  transform: translateY(0);
}
</style>

<!-- Styles globaux pour le ripple -->
<style lang="scss">
.ripple {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  transform: scale(0);
  animation: ripple 1.1s ease-out;
  pointer-events: none;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
</style>
